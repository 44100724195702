;(function () {
    'use strict';

    function imageMapMouseOver(e)
    {
        var target = e.target;

        var imgURL = target.getAttribute('data-imagemap-img');
        var img = document.querySelector('.js-imagemap-img');
        img.setAttribute('src', imgURL);

        var iconURL = target.getAttribute('data-imagemap-icon');
        if (iconURL) {
            var iconEl = document.querySelector('.js-imagemap-icon');
            iconEl.setAttribute('src', iconURL);
        }

        var title = target.getAttribute('title');
        var titleEl = document.querySelector('.js-imagemap-title');
        titleEl.innerHTML = title;

        var selectedEl = document.querySelector('.js-imagemap-selected');
        if (!selectedEl.classList.contains('is-active')) {
            selectedEl.classList.add('is-active');
        }
    }

    function imageMapMouseOut(e)
    {
        var target = e.target;

        var img = document.querySelector('.js-imagemap-img');
        var allImgURL = img.getAttribute('data-imagemap-all');
        img.setAttribute('src', allImgURL);

        var selectedEl = document.querySelector('.js-imagemap-selected');
        if (selectedEl.classList.contains('is-active')) {
            selectedEl.classList.remove('is-active');
        }

        var iconEl = document.querySelector('.js-imagemap-icon');
        iconEl.removeAttribute('src');

        var titleEl = document.querySelector('.js-imagemap-title');
        titleEl.innerHTML = '';
    }

    var imageMaps = document.querySelectorAll('.js-imagemap');
    Array.prototype.forEach.call(imageMaps, function(imageMap, i)
    {
        var map = imageMap.querySelector('.js-imagemap-map');
        var img = imageMap.querySelector('.js-imagemap-img');

        var preloadImageURLs = [];

        if (typeof map !== 'undefined' && typeof img !== 'undefined' && img !== null)
        {
            var areas = map.querySelectorAll('.js-imagemap-area');
            if (areas.length > 0)
            {
                Array.prototype.forEach.call(areas, function(area, i)
                {
                    var areaKey = area.getAttribute('data-imagemap-key');
                    var areaImgURL = area.getAttribute('data-imagemap-img');
                    if (
                        typeof areaKey !== 'undefined' && areaKey !== null
                        && typeof areaImgURL !== 'undefined' && areaImgURL !== null
                    )
                    {
                        preloadImageURLs.push(areaImgURL);
                        area.addEventListener('mouseover', imageMapMouseOver, false);
                        area.addEventListener('mouseout', imageMapMouseOut, false);
                    }
                });
            }
        }

        var preloadedImages = [];
        for (i = 0; i < preloadImageURLs.length; i++) {
            preloadedImages[i] = new Image()
            preloadedImages[i].src = preloadImageURLs[i]
        }
    });
}());
