;(function () {
    'use strict';

    var connection = false;
    var slowConnection = false;
    var reducedMotionQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    function updateConnectionStatus() {
        console.log('fontcheck: update connection status');
        if (typeof(Storage) !== "undefined" && sessionStorage.getItem("fontsLoaded") == "true") {
            // reset storage flag
            sessionStorage.setItem("fontsLoaded", "false");
        }
    }

    if ("connection" in navigator || "mozConnection" in navigator || "webkitConnection" in navigator) {
        connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        // add update handler
        connection.addEventListener('change', updateConnectionStatus);
        // update connection speed flag
        slowConnection = (connection.effectiveType === 'slow-2g' || connection.effectiveType === '2g' || connection.effectiveType === '3g');
    }

    if (typeof(Storage) !== "undefined" && sessionStorage.getItem("fontsLoaded") == "true") {
        var htmlEl = document.documentElement;
        if (!htmlEl.classList.contains("fonts-loaded")) {
            htmlEl.classList.add("fonts-loaded");
        }
    }
    else {
        if (connection && connection.saveData === true) {
            // data saver is on, don't load web fonts
            console.log('fontcheck: data saver');
        } else if (slowConnection) {
            // slow connection, don't load web font
            console.log('fontcheck: slow connection');
        } else if (reducedMotionQuery && reducedMotionQuery.matches) {
            // reduced motion: don't load web fonts and prevent reflow
            console.log('fontcheck: reduced motion');
        } else {

            console.log('fontcheck: load web fonts');

            var MyriadPro = new FontFaceObserver("MyriadProBoldCondensed", {weight: 400, style: "normal"});
            var Roboto400 = new FontFaceObserver("Roboto", {weight: 400, style: "normal"});
            var Roboto400i = new FontFaceObserver("Roboto", {weight: 400, style: "italic"});
            var Roboto500 = new FontFaceObserver("Roboto", {weight: 500, style: "normal"});

            Promise.all([
                MyriadPro.load(),
                Roboto400.load(),
                Roboto400i.load(),
                Roboto500.load()
            ])
                .then(function() {
                    document.documentElement.classList.add("fonts-loaded");
                    if (typeof(Storage) !== "undefined") {
                        sessionStorage.setItem("fontsLoaded", "true");
                    }
                })
                .catch(function (err) {
                    document.documentElement.classList.remove("fonts-loaded");
                    if (typeof(Storage) !== "undefined") {
                        sessionStorage.setItem("fontsLoaded", "false");
                    }
                    console.warn("fontcheck: Some fonts did not load:", err);
                });
        }
    }

}());
