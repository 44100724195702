;(function () {
    'use strict';

    var doFilter = function (event)
    {
        var filter = getClosest(event.target, '.js-filter');
        var filterURL = filter.getAttribute('data-filter-url');
        var filterAnchor = filter.getAttribute('data-filter-anchor');
        var controls = filter.querySelectorAll('.js-filter-control');
        Array.prototype.forEach.call(controls, function(control)
        {
            var controlValue = control.value;
            var controlKey = control.getAttribute('data-filter-key');
            if (controlValue && controlKey)
            {
                filterURL += filterURL.includes('?') ? '&' : '?';
                filterURL += controlKey + '=' + controlValue;
            }
        });
        if (filterURL.includes('?') && filterAnchor) {
            filterURL += filterAnchor;
        }
        window.location = filterURL;
    }

    var controls = document.querySelectorAll('.js-filter .js-filter-control');
    Array.prototype.forEach.call(controls, function(control) {
        control.addEventListener('input', doFilter);
    });

}());
